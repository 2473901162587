import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthGet, AuthPost } from "../../common_var/httpService";
import BootstrapTable from "react-bootstrap-table-next";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import Deliquency from "../Tabs/deliquency";
import { useStateValue } from "../../Context/StateProvider";
import LoanStagetable from "../../Components/LoanTracker/LoanStagetable";
import dropdownicon from "../../assets/images/dropdown_icon.svg";


const Loanlist = () => {
  const navigate = useNavigate();
  const queryParams = useLocation();
  let loanParams = queryParams.pathname.slice(7);
  const loanStatus = `${
    loanParams.charAt(0).toUpperCase() + loanParams.slice(1)
  } `;
  const [loanData, setLoanData] = useState([]);
  console.log('loanData::: ', loanData);
  const [totalCount, setTotalCount] = useState(0);
  let [itemsPerPage, setItemsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  console.log("currentPage::: ", currentPage);
  const [deliquencyData, setDeliquencyData] = useState([]);
  const [deliquencypage, setDeliquencypage] = useState(1);
  const [deliquencycurrentPage, setDeliquencycurrentPage] = useState(1);
  const [activeTab, setActiveTab] = useState(1);
  const [{ currency,initialState }, dispatch] = useStateValue();
  const [searchKey, setSearchKey] = useState("");

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
    if (tabId == 1) {
      getDeliquency({
        start: 0,
        end: 30,
      });
    }
    if (tabId == 2) {
      getDeliquency({
        start: 31,
        end: 60,
      });
    }
    if (tabId == 3) {
      getDeliquency({
        start: 61,
        end: 90,
      });
    }
  };

  useEffect(() => {
    getlist();
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
    getlist();
  }, [loanParams, searchKey]);

  useEffect(() => {
    getDeliquency({
      start: 0,
      end: 30,
    });
  }, []);

  const getlist = async () => {
    await AuthGet(
      `loan/loan-applications/${
        loanParams === "approved"
          ? "approvedcontract"
          : loanParams === "archived"
          ? "archive"
          : loanParams === "denied"
          ? "canceled"
          : loanParams === "applications"
          ? "milestones"
          : loanParams
      }?search=${encodeURIComponent(searchKey)}&limit=10&offset=${currentPage}`,
      "admin"
    )
      .then((res) => {
        if (res["statusCode"] == 200) {
          setLoanData(res?.data);
          setTotalCount(res?.count);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDeliquency = async (body) => {
    await AuthPost(`loanstage/delinquency`, body, "admin")
      .then((res) => {
        if (res["statusCode"] == 200) {
          // console.log("Res Data: ", res);
          setDeliquencyData(res.data);
          setDeliquencypage(Math.ceil(loanData.length / 10));
          setDeliquencycurrentPage(1);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const initial = [
    {
      heading: () => "Date",
      cell: (row) => {
        return row["app_date"];
      },
      hidden: false,
      width: "130",
      id: 1,
    },
    {
      heading: () => "Loan Ref",
      cell: (row) => row["ref_no"],
      hidden: false,
      width: "150",
      id: 2,
    },
    {
      heading: () => "Borrower",
      cell: (row) =>
        row["firstname"] === null
          ? "NA"
          : row["firstname"] + " " + row["lastname"],
      // +
      // " ( " +
      // row["vehicle"] +
      // " ) ",
      hidden: false,
      width: "120",
      id: 3,
    },
    {
      heading: () => "Net Check (Monthly Payment)",
      cell: (row) => {
        const purchasePrice = row["purchase_price"];
        const monthlyAmount = row["monthlyamount"]?.trim() || "NA";

        const formattedMonthlyAmount =
          monthlyAmount === "NA"
            ? monthlyAmount
            : `${currency}${monthlyAmount}`;
        return `${currency}${purchasePrice} ( ${formattedMonthlyAmount} )`;
      },
      hidden: false,
      width: "200",
      id: 4,
    },
    {
      heading: () => "Installer",
      cell: (row) => row["practicename"] ?? "",
      hidden: false,
      width: "150",
      id: 5,
    },
    {
      heading: () => "Status",
      cell: (row) => {
        return (
          <>
            <td>
              <div className="status_percent">
                <div
                  className={
                    row?.loan_status === "completed"
                      ? "pendingprogress completeprogress"
                      : "pendingprogress"
                  }
                >
                  {row?.loan_status === "approvedcontract"
                    ? "APPROVED"
                    : row?.loan_status === "fundingcontract"
                    ? "FUND DISPERSED"
                    : row?.loan_status === "applications"
                    ? "APPLICATIONS"
                    : row?.loan_status === "denied"
                    ? "CANCELED"
                    : row?.loan_status?.toUpperCase()}
                </div>
              </div>
            </td>
            <td>
              <div
                onClick={() => handleDropDown(row)}
                className={
                  row?.isDrop
                    ? "cursorpointer active_dropdown"
                    : "cursorpointer"
                }
              >
                <img src={dropdownicon} />
              </div>
            </td>
          </>
        );
      },
      hidden: false,
      width: "150",
      id: 6,
    },
    {
      heading: () => "Address",
      cell: (row) => row["address"],
      hidden: true,
      width: "150",
      id: 7,
    },
    {
      heading: () => "Country",
      cell: (row) => row["country"],
      hidden: true,
      width: "100",
      id: 8,
    },
    {
      heading: () => "State",
      cell: (row) => row["state"],
      hidden: true,
      width: "100",
      id: 9,
    },
    {
      heading: () => "City",
      cell: (row) => row["city"],
      hidden: true,
      width: "100",
      id: 10,
    },
    {
      heading: () => "Zip Code",
      cell: (row) => row["zipcode"],
      hidden: true,
      width: "60",
      id: 11,
    },
  ];

  const [column, setColumn] = useState(initial);

  useEffect(() => {
    setColumn(initial);
  }, [loanData]);

  const handleDropDown = (data) => {
    console.log("data::: ", data);
    const updatedUsers = loanData?.map((user) => {
      if (user?.loan_id === data?.loan_id) {
        return {
          ...user,
          isDrop: !data?.isDrop, // Toggle the value of isDrop
        };
      } else {
        return {
          ...user,
          isDrop: false, // Set isDrop to false for other objects
        };
      }
    });
    setLoanData(updatedUsers);
  };

  return (
    <div>
      <LoanStagetable
        row={loanData}
        column={column}
        page={loanStatus}
        totalCount={totalCount}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        loanParams={loanParams}
        handleDropDown={handleDropDown}
        searchKey={searchKey}
        setSearchKey={setSearchKey}
        // isFilterHeader={true}
        // filterOptions={filterOptions}
        // handleFilterSelection={handleFilterSelection}
        // filterDefaultValue={filterDefaultValue}
        // handleExport={handleOwnersExport}
        // isExport
      />

      {/* <div className="frameshadow">
        <ToolkitProvider
          bootstrap4
          keyField="id"
          data={loanData}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <SearchBar {...props.searchProps} />
              <ClearSearchButton {...props.searchProps} />
              <hr />
              <BootstrapTable
                bootstrap4
                key="loan_ref"
                defaultSorted={defaultSorted}
                pagination={pagination}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div> */}

      {loanStatus === "Fundingcontract " ? (
        <div className="breadcrumbsection mt_24">
          <div>
            <div className="titleText ">Delinquency</div>
            {/* <div className=" ">

                            <Tabs
                                transition={true}
                                id="noanim-tab-example"
                                className="mb-3 tabblock"
                                activeKey={activeTab}
                                unmountOnExit={true}
                                mountOnEnter={true}
                                onSelect={handleTabClick}
                            >
                                <Tab title="0-30" eventKey={1}>
                                    <div className="frameshadow">
                                        <ToolkitProvider
                                            bootstrap4
                                            keyField='id'
                                            data={deliquencyData}
                                            columns={columns1}
                                            search
                                        >
                                            {
                                                props => (
                                                    <div>
                                                        <SearchBar {...props.searchProps} />
                                                        <ClearSearchButton {...props.searchProps} />
                                                        <hr />
                                                        <BootstrapTable
                                                            bootstrap4
                                                            key='loan_ref'
                                                            // defaultSorted={defaultSorted}
                                                            pagination={pagination}
                                                            {...props.baseProps}
                                                        />
                                                    </div>
                                                )
                                            }
                                        </ToolkitProvider>
                                    </div>

                                </Tab>
                                <Tab title="31-60" eventKey={2}>

                                    <div className="frameshadow">
                                        <ToolkitProvider
                                            bootstrap4
                                            keyField='id'
                                            data={deliquencyData}
                                            columns={columns1}
                                            search
                                        >
                                            {
                                                props => (
                                                    <div>
                                                        <SearchBar {...props.searchProps} />
                                                        <ClearSearchButton {...props.searchProps} />
                                                        <hr />
                                                        <BootstrapTable
                                                            bootstrap4
                                                            // key='loan_ref'
                                                            defaultSorted={defaultSorted}
                                                            pagination={pagination}
                                                            {...props.baseProps}
                                                        />
                                                    </div>
                                                )
                                            }
                                        </ToolkitProvider>
                                    </div>
                                </Tab>
                                <Tab title="61-" eventKey={3}>

                                    <div className="frameshadow">
                                        <ToolkitProvider
                                            bootstrap4
                                            keyField='id'
                                            data={deliquencyData}
                                            columns={columns1}
                                            search
                                        >
                                            {
                                                props => (
                                                    <div>
                                                        <SearchBar {...props.searchProps} />
                                                        <ClearSearchButton {...props.searchProps} />
                                                        <hr />
                                                        <BootstrapTable
                                                            bootstrap4
                                                            key='loan_ref'
                                                            defaultSorted={defaultSorted}
                                                            pagination={pagination}
                                                            {...props.baseProps}
                                                        />
                                                    </div>
                                                )
                                            }
                                        </ToolkitProvider>
                                    </div>
                                </Tab>
                            </Tabs>
                        </div> */}
            <Deliquency />
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Loanlist;
