import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Post } from "../../common_var/httpService";
import Toast from "../../Components/Toaster/Toast";
import { useStateValue } from "../../Context/StateProvider";
import toast from "react-hot-toast";
const UserInfromation = ({ userData }) => {
  const [showBtn, setShowBtn] = useState(false);
  const queryParams = useLocation();
  const navigate = useNavigate();
  let params = queryParams.pathname.split("/");
  const loanId = params[params.length - 1];
  const loanStage = params[params.length - 2];
  const [{ currency }, dispatch] = useStateValue();

  // useEffect(() => {
  //   getlist();
  // }, []);

  // const getlist = async () => {
  //   console.log("params Id: ", loanId);
  //   console.log("stage: ", loanStage);

  //   await AuthGet(`loanstage/${loanId}/${loanStage}`, "admin")
  //     .then((res) => {
  //       if (res["statusCode"] == 200) {
  //         let procedureData = {
  //           procedure_startdate: res.data.from_details[0].procedure_startdate,
  //           payment_duedate: res.data.from_details[0].payment_duedate,
  //           loanamount: res.data.from_details[0].loanamount,
  //         };
  //         console.log("Session procedure data: ", procedureData);
  //         sessionStorage.setItem(
  //           "procedure_date",
  //           JSON.stringify(procedureData)
  //         );
  //         setUserData({
  //           data: res.data.from_details[0],
  //           coborrower: res.data.CoApplicant[0],
  //           stage: res.data.stage[0].status_flag,
  //         });
  //       } else if (res["statusCode"] == 500) {
  //         toast.error(res["message"]);
  //         // dispatch({
  //         //   type: "TOAST",
  //         //   toastMessage: res["message"],
  //         //   toastType: "error",
  //         // });
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const style = {
    width: "50%",
  };
  const ssnFormat = (number) => {
    let result = "";
    for (let i = 0; i < number?.length - 4; i++) {
      result = result + "X";
    }
    // console.log(result + number.substring(number.length - 4))
    return result + number?.substring(number?.length - 4);
  };

  const handleResend = async () => {
    const body = { email: userData.data?.email };
    await Post("loanstage/flow", body, "admin").then((res) => {
      if (res.status == 200) {
        toast.success(res.message);
      }
    });
  };

  const handlebankLink = async () => {
    await Post(`loanstage/resendInviteBankLink/${loanId}`, {}, "admin")
      .then((res) => {
        console.log("res::: ", res);
        if (res.status == 200) {
          toast.success(res.message);
        }else if(res.status == 400){
          toast.error(res.message)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getBankDetails = async () => {
    await Post(`loanstage/checkProvider/${loanId}`, {}, "admin")
      .then((res) => {
        if (res.data.length === 0) {
          setShowBtn(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getBankDetails();
  }, []);

  return (
    <>
      <Toast />
      {/* <div>
        <h6>User Information</h6>
      </div> */}
      <div className="p20 frameshadow" style={{ marginBottom: "20px" }}>
        <div className="heading-box">
          <h5>Borrower Contact Information</h5>
          <div>
            {(loanStage === "waiting" || loanStage === "pending") && (
              <button
                type="button"
                className="btn btn-primary mb_15"
                onClick={() => {
                  handleResend();
                }}
              >
                Send Form Link
              </button>
            )}{" "}
            {(showBtn && loanStage !== "fundingcontract") && (
              <button
                type="button"
                className="btn btn-primary mb_15"
                onClick={() => {
                  handlebankLink();
                }}
              >
                Installer Bank Link
              </button>
            )}
          </div>
        </div>
        <table border={1} className="userinfotable">
          <tbody>
            {/* <tr>
              <td className="w30percent">User Reference</td>
              <td> USR {userData.data?.ref_no}</td>
              <td> </td>
            </tr> */}
            <tr>
              <td style={style}>Name </td>
              <td
                onClick={() =>
                  navigate(`/admin/users/${userData.data?.user_id}`)
                }
                style={{
                  color: "blue",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                {userData.data?.firstname} {userData.data?.middle_initial}{" "}
                {userData.data?.lastname}
              </td>
              <td> </td>
            </tr>
            <tr>
              <td> Email </td>
              <td>{userData.data?.email  ?? '-'}</td>
              <td> </td>
            </tr>

            <tr>
              <td> Phone Number</td>
              <td>{userData.data?.phone  ?? '-'}</td>
              <td> </td>
            </tr>
            <tr>
              <td> Date of Birth </td>
              <td>{userData.data?.dob  ?? '-'}</td>
              <td> </td>
            </tr>
            <tr>
              <td>SSN</td>
              <td>
                {userData?.data?.ssn
                  ? ssnFormat(userData.data?.ssn)
                  : "XXXXXXXX"}
              </td>
              <td> </td>
            </tr>

            <tr>
              <td> Address </td>
              <td>
                {userData.data?.unit} {userData.data?.street_address}{" "}
                {userData.data?.city}
              </td>
              <td> </td>
            </tr>

            <tr>
              <td> State </td>
              <td>{userData.data?.state  ?? '-'}</td>
              <td> </td>
            </tr>

            {userData.stage === "fundingcontract" ? (
              <tr>
                <td> Procedure Start Date </td>
                <td>{userData.data?.procedure_startdate  ?? '-'}</td>
                <td> </td>
              </tr>
            ) : (
              ""
            )}

            <tr>
              <td> Signed Up On </td>
              <td>{userData.data?.created_at  ?? '-'}</td>
              <td> </td>
            </tr>

            <tr>
              <td> Annual Income </td>
              <td>
                {currency} {userData.data?.annual_income  ?? '-'}
              </td>
              <td> </td>
            </tr>
            <tr>
              <td> Monthly Income </td>
              <td>
                {currency} {userData.data?.monthly_income  ?? '-'}
              </td>
              <td> </td>
            </tr>
            <tr>
              <td> Requested Loan Amount </td>
              <td>{currency} {userData.data?.loanamount  ?? '-'} </td>
              <td> </td>
            </tr>

            {/* <tr>
              <td> Mode Of Income </td>
              <td>{userData.data?.income}</td>
              <td> </td>
            </tr> */}
            {/* <tr>
              <td> Credit Qualified </td>
              <td>--</td>
              <td> </td>
            </tr>

            <tr>
              <td> AAN </td>
              <td>--</td>
              <td> </td>
            </tr> */}

            <tr>
              <td> Loan Originator </td>
              <td>
                {userData.data?.firstname} {userData.data?.middle_initial}{" "}
                {userData.data?.lastname}
              </td>
              <td> </td>
            </tr>

            <tr>
              <td> Sales Rep </td>
              <td>
                {userData.data?.sales_rep_email
                  ? userData.data?.sales_rep_email
                  : "-"}
              </td>
              <td> </td>
            </tr>
          </tbody>
        </table>
      </div>

      {userData?.coborrower ? (
        <div className="p20 frameshadow">
          <h5>Co-Borrower Contact Information</h5>
          <table border={1} className="userinfotable">
            <tbody>
              <tr>
                <td style={style}>Name </td>
                <td>
                  {userData.coborrower?.firstname}{" "}
                  {userData.coborrower?.middle_initial}{" "}
                  {userData.coborrower?.lastname}
                </td>
                <td> </td>
              </tr>
              <tr>
                <td> Email </td>
                <td>{userData.coborrower?.email  ?? '-'}</td>
                <td> </td>
              </tr>

              <tr>
                <td> Phone Number</td>
                <td>{userData.coborrower?.phone  ?? '-'}</td>
                <td> </td>
              </tr>
              <tr>
                <td> Date of Birth </td>
                <td>{userData.coborrower?.dob  ?? '-'}</td>
                <td> </td>
              </tr>

              <tr>
                <td>SSN</td>
                <td>{ssnFormat(userData.coborrower?.ssn  ?? '-')}</td>
                <td> </td>
              </tr>

              <tr>
                <td> Address </td>
                <td>
                  {userData.coborrower?.unit}{" "}
                  {userData.coborrower?.street_address}{" "}
                  {userData.coborrower?.city}
                </td>
                <td> </td>
              </tr>

              <tr>
                <td> State </td>
                <td>{userData.coborrower?.state  ?? '-'}</td>
                <td> </td>
              </tr>

              {/* {userData.coborrower === "fundingcontract" ? 
                  <tr>
                    <td> Procedure Start Date </td>
                    <td>{userData.coborrower?.procedure_startdate}</td>
                    <td> </td>
                  </tr> : ""
                } */}

              <tr>
                <td> Signed Up On </td>
                <td>{userData.coborrower?.created_at ?? '-'}</td>
                <td> </td>
              </tr>

              <tr>
                <td> Annual Income </td>
                <td>{currency}{userData.coborrower?.annual_income  ?? '-'}</td>
                <td> </td>
              </tr>
              <tr>
                <td> Monthly Income </td>
                <td>{currency}{userData.coborrower?.monthly_income  ?? '-' }</td>
                <td> </td>
              </tr>

              {/* <tr>
                  <td> Mode Of Income </td>
                  <td>{userData.coborrower?.income}</td>
                  <td> </td>
                </tr> */}
            </tbody>
          </table>
        </div>
      ) : (
        ""
      )}

      <div></div>
    </>
  );
};

export default UserInfromation;
