import React, { useEffect, useState } from "react";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Get } from "../../common_var/httpService";
import "./landing.css";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../Components/Button/Button";
import StartPage from "./StartPage";
import { useStateValue } from "../../Context/StateProvider";
import Brandlogo from "../../assets/images/brand_logo.svg";
import USlogo from "../../assets/images/united-states.jpg";
import banner from "../../assets/images/h3_banner_img.jpg";
import about1 from "../../assets/images/about_img01.jpg";
import about2 from "../../assets/images/about_img02.jpg";
import abouticon1 from "../../assets/images/about_icon01.svg";
import cloudimg from "../../assets/images/cloudimg.png";
import services_icon01 from "../../assets/images/services_icon01.svg";
import services_icon02 from "../../assets/images/services_icon02.svg";
import team_img1 from "../../assets/images/team_img1.jpg";
import team_img2 from "../../assets/images/team_img2.jpg";
import team_img3 from "../../assets/images/team_img3.jpg";
import blogimg from "../../assets/images/blogimg.jpg";
import blogimg3 from "../../assets/images/blogimg03.jpg";
import blogimg2 from "../../assets/images/blogimg02.jpg";
import footerimg1 from "../../assets/images/footerimg.jpg";
import footerimg2 from "../../assets/images/footerimg2.jpg";
import footerlogo from "../../assets/images/w_logo.svg";

function Landing() {
  const queryParams = useLocation()
  const [{currency }, dispatch] = useStateValue();
  const [loanPopup, setLoanPopup] = useState(false);
  const navigate = useNavigate();
  const [mobilemenu, setMobilemenu] = useState(false);
  const [mobileToggle, setMobileToggle] = useState(false);
  let providername = queryParams.pathname?.split("/")

  const handleClick = () => {
    // setLoanPopup(true);
    dispatch({
      type: "SET_LOANAMOUNT_POPUP",
      payload: true,
    });
    // navigate("/select-loan");
    if(providername[1]){
     window.open(`/select-loan/${providername[1]}`)
    }else{
    window.open("/select-loan", "_blank");
  }
};
  const handleMobileClick = () => {
    setMobilemenu((current) => !current)
  }
  const handleMenuClick = () => {
    setMobileToggle((current) => !current)
  }

  const handleLogin = () => {
    navigate("/borrower/login");
  };


  const SliderSec = {
    dots: true,
    arrows: false,
    infinite: true,
    rtl: true,
    autoplay: true,
    autoplaySpeed: 2000,
    initialSlide: 3,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };
  return (
    <div>
   <div>
     {/* <div className="landingPage">
      {loanPopup && <StartPage />}
      <nav class="navbar navbar-expand-lg navbar-light logoheader plr40">
        <div class="container-fluid">
          <a class="navbar-brand brand_logo" href="#">
            <img src={Brandlogo} className="whiteFilter" />
          </a>
        

          <button onClick={handleMobileClick} class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class={mobilemenu ? "collapse navbar-collapse " : "show navbar-collapse"} id="navbarSupportedContent">
            <div>
              <ul class="navbar-nav me-auto mb-2 mb-lg-0 pr100" >
                <li class="nav-item">
                  <a class="nav-link active" aria-current="page" href="#home">
                    Home
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#howitwork">
                    How it works
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#benefit">
                    Benefits
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#contact">
                    Contact us
                  </a>
                </li>


              </ul>
            </div>


            <div className="d-flex">
              <div className="applybutton cursorpointer" onClick={handleClick}>
                Start Now
              </div>
              <div className="applybutton2 cursorpointer" onClick={handleLogin}>
                Login
              </div>
            </div>

         
          </div>


        </div>
      </nav>
      <div className="container-fluid " id="home">
        <div className="row pt78">

        <div className="col-md-6 bannerimg plr0">
            <img  src={bannermain} />

          </div>
          <div className="col-md-6 bannercontentsection pr100">
            <div className="bannertitlefont">
              <span className="grey_text">Enabling</span> Affordable & Instant Solar Loans
            </div>
            <div className="pb30 bannerpara">
              A smarter and cost effective loan to make your medical treatments
              independent of bargains. Our functionally scalable solutions
              achieves your financial needs effectively.
            </div>
            <Button
              className="bg_grey  "
              type="button"
              value={"Start Now"}
              handleClick={handleClick}
            />
          </div>
         


        </div>
      </div>

      <section className="pt80">
      <div className="container">
          <div className="row alignItemcenter">
         <div className="col-md-6 clientsection">
     <h3 className="pb30">
     All Over India <span className="text_red">50+</span> Customers using our applications
     </h3>
          <div className="dasharrow">
            <img  src={dasharrow} alt="" />
          </div>
         </div>
           
            <div className="col-md-6 containerr blur bannercontentsection ">
             
              <div className="row gap40 slider">
                <div className="col-md-5">
                  <img src={samplelogo} alt="" />
                </div>
                <div className="col-md-5">
                  <img src={samplelogo} alt="" />
                </div>
                <div className="col-md-5">
                  <img src={samplelogo} alt="" />
                </div>
                <div className="col-md-5">
                  <img src={samplelogo} alt="" />
                </div>
                <div className="col-md-5">
                  <img src={samplelogo} alt="" />
                </div>
                <div className="col-md-5">
                  <img src={samplelogo} alt="" />
                </div>
                <div className="col-md-5">
                  <img src={samplelogo} alt="" />
                </div>
                <div className="col-md-5">
                  <img src={samplelogo} alt="" />
                </div>
                <div className="col-md-5">
                  <img src={samplelogo} alt="" />
                </div>
                <div className="col-md-5">
                  <img src={samplelogo} alt="" />
                </div>
              </div>
            </div>
            </div>

          </div>
        
      </section>

      <section className="pt80 " id="howitwork">
        <div className="container-fluid">
          <div className="row columnreverse_mob">
          <div className="col-md-6 bannercontentsection pl150">
              <p className="sectionsubtitle pb10"> How it Works?</p>

              <p className="bannerpara pb30">
                We have simplified the process to empower you to avail Solar
                loans quickly.using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy
using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.
              </p>

              <button className="greyborderbtn">
                <div>Read more</div>
                <div>
                  <img src={readmorearrow} alt="" />
                </div>
              </button>

          
            </div>
            <div className="col-md-6 plr0 ">
             <img className="w100per" src={howitworks} alt="" />
            </div>
         

          </div>
        </div>
      </section>

      <section className="pt80" id="benefit">
        <div className="container-fluid ">
          <div className="row alignItemcenter">
          <div className="col-md-6 plr0">
              <img className="w600" src={benefits} />
            
            </div>
            <div className="col-md-6  ">
              <div>
              <p className="sectionsubtitle   pb10 ">Benefits</p>
              <p className="bannerpara pt15  ">
                <span className="mr10">
                  <img src={dashtick} alt="" />
                </span>
              when an unknown printer took a galley of type and scrambled it to type
              </p>
              <p className="bannerpara pt15 ">
                <span className="mr10">
                  <img src={dashtick} alt="" />
                </span>
              when an unknown printer took a galley of type and scrambled it to type
              </p>
              <p className="bannerpara pt15  pb30">
                <span className="mr10">
                  <img src={dashtick} alt="" />
                </span>
              when an unknown printer took a galley of type and scrambled it to type
              </p>
              </div>
              <div className="alignItemcenter displayFlex">
                <button className="greybgbtn mr30">Check Eligibility</button>
                <button className="greyborderbtn">
                <div>Read more</div>
                <div>
                  <img src={readmorearrow} alt="" />
                </div>
              </button>
              </div>
            </div>
            
          </div>

    
        </div>
      </section>
      <section className="mt80 mb50 contactbg " id="contact">
        <div className="container ptb60">
          <div className="row ">
            <div className="col-md-12  p0_mob">
              <div className="pb15 sectionsubtitle  textaligncenter ">
                Contact Us
              </div>
              <div className="contacttext">
              As we envision the future in light of the challenges and opportunities of the present, we see the need  As we envision the future in light of the challenges and opportunities of the present, we see the need .
              </div>
            </div>
          </div>

          <div className="row flexcolumnmob ">
            <div className="col-md-6 w100mob contactsection">
              <div className="whiteframe ">
              
                <div>
                  <div className="contacttitle ">Call Us Now</div>
                  <div className="contactcontect">
                    Need help with your Login? No problem! <br /> Our team is here
                    to help you. <br /> 508.475.9013
                  </div>
                  <div className="divcenter">
                  <button className="redbtn">
                <div>Call Now</div>
                <div>
                  <img src={readmorearrow} alt="" />
                </div>
              </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 w100mob">
              <div className="whiteframe bordergrey">
            
                <div>

                  <div className="contacttitle">Request a Quote</div>
                  <div className="contactcontect">
                    If you prefer to communicate via email, please click below.

                    Please feel free to ask any questions may you have, we
                    would love to hear from you.
                  </div>
                  <div className="divcenter">
                  <button className="greybtn">
                <div>Lets Talk</div>
                <div>
                  <img src={readmorearrow} alt="" />
                </div>
              </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pt80  footer_bg">
        <div className="container">
          <div className="row ">
            <div className="col-md-12 pb30 ">
              <div className="brand_logo">
                <img src={Brandlogo} className="whiteFilter" />
              </div>
            </div>
            <div className="row">
            <div className="col-md-2">


<div className="footerlist">
  <ul className="">
    <li>
      <a href="#home"><span style={{ color: "#ffffff" }}>Home</span></a>
    </li>
    <li>
      <a href="#howitwork"><span style={{ color: "#ffffff" }}>How it works?</span></a>
    </li>
    <li>
      <a href="#benefit"><span style={{ color: "#ffffff" }}>Benefits</span></a>
    </li>
    <li>
      <a href="#contact"><span style={{ color: "#ffffff" }}>Contact us</span></a>
    </li>
  </ul>
</div>
</div>
<div className="col-md-5">
<p className=" footertext">
As we envision the future in light of the challenges and opportunities of the present, we see the need  As we envision the future in light of the challenges and opportunities of the present, we see the need the printing and typesetting industry. Lorem Ipsum has been the industry's standard
</p>
</div>
<div className="col-md-5">
<div className="footertext">
<h5>
Why do we use it
</h5>
  <p>
  text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
when an unknown printer took a galley of type and scrambled

  </p>
</div>

</div>
            </div>
           
          </div>
        </div>

      </section>
      <section className="footer_bg">
        <div className="footerline "></div>
      <div className="container">
      <div className="row fs12 pb15">
          <div className="col-md-6 pb15">
            <div>
              Copyright © 2020-2023 Glenzy Technologies Private Limited. All
              Rights Reserved.
            </div>
          </div>
          <div className="col-md-6 displayFlex justifyend pb15">
            <div className="footerbottomlist">
              <ul className="displayFlex alignItemcenter">
                <li>
                  <a href="#"> <span style={{ color: "#5A7072" }}>Terms & Conditions</span></a>
                </li>
                <li>|</li>
                <li>
                  <a href="#"><span style={{ color: "#5A7072" }}>Privacy Policy</span></a>
                </li>
                <li>|</li>
                <li>
                  <a href="#"> <span style={{ color: "#5A7072" }}>Accessibility</span></a>
                </li>
                <li>|</li>
                <li>
                  <a href="#"><span style={{ color: "#5A7072" }}>Legal</span></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      </section>
    </div>    */}
   </div>


  <div>

  {loanPopup && <StartPage />}
  <div className="solarlanding">
    <a href="#top">
  <button class="scroll-top scroll-to-target" data-target="html">
  <i class="fas fa-level-up-alt"></i>
        </button></a>
        
        <div className="headerFixed">
            {/* <div id="header-fixed-height"></div> */}
            <div class="header-top-wrap">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-8 col-lg-9">
                            <div class="header-top-left">
                                <ul class="list-wrap">

                                    <li><i class="fas fa-phone-alt"></i><a href="tel:
                                        (+1) 480-409-0695">
                                        (+1) 480-409-0695</a></li>
                                    <li><i class="fas fa-envelope"></i><a href="mailto:glenzyfi.com">https://glenzyfi.com</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-3">
                            <div class="header-top-right">
                                <div class="header-lang">
                                    <div class="dropdown">
                                        <button>
                                            <img src={USlogo}/> English
                                        </button>
                                        
                                    </div>
                                </div>
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="menu-area menu-area-three ">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="mobile-nav-toggler" onClick={handleMenuClick}><i class="fa fa-bars"></i></div>
                            <div class="menu-wrap">
                                <nav class="menu-nav">
                                    <div class="logo">
                                        <a href="#"> <img src={Brandlogo} className="whiteFilter" /></a>
                                    </div>
                                    <div class="navbar-wrap main-menu d-none d-lg-flex">
                                        <ul class="navigation">
                                            <li class="active "><a href="#">Home</a>
                                             
                                            </li>
                                            <li><a href="#howitworks">How it Works</a></li>
                                            <li class=""><a href="#benefits">Benefits</a>
                                            
                                            </li>
                                            <li class=""><a href="#contactus">Contact Us</a>
                                               
                                            </li>
                                            
                                           
                                        </ul>
                                    </div>
                                    <div class="header-action d-none d-md-block">

                                        <ul class="list-wrap">
                                            <li onClick={handleClick} class="header-btn"><a class="btn ">Start Now</a></li>
                                            <li class="header-btn"><a class="btn bg_secondary" onClick={handleLogin}>Log In</a></li>
                                        </ul>
                                    </div>
                                </nav>
                            </div>

                            <div className={mobileToggle ? 'mobile-menu show' : 'mobile-menu'}>
                                <nav class="menu-box">
                                    <div class="close-btn" onClick={handleMenuClick}><i class="fas fa-times"></i></div>
                                    <div class="nav-logo">
                                    <a href="#"> <img src={Brandlogo} className="whiteFilter" /></a>
                                    </div>
                                    <div class="menu-outer">
                                    <ul class="navigation">
                                            <li class="active "><a href="#">Home</a>
                                             
                                            </li>
                                            <li><a href="#howitworks">How it Works</a></li>
                                            <li class=""><a href="#benefits">Benefits</a>
                                            
                                            </li>
                                            <li class=""><a href="#contactus">Contact Us</a>
                                               
                                            </li>
                                            
                                           
                                        </ul>
                                    </div>
                                 
                                </nav>
                            </div>
                            <div class="menu-backdrop"></div>
                           

                        </div>
                    </div>
                </div>
            </div>
        </div>
      


         
        <main>

           
            <section class="banner-area-two parallax">
                <div class="container">
                    <div class="row align-items-center justify-content-center">
                        <div class="col-lg-6 col-md-10 order-0 order-lg-2">
                            <div class="banner-img-wrap">
                                <img src={banner} />
                                <div class="overly-text">
                                    <h2 class="title">Glenzy</h2>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="banner-content-two">
                                <span class="sub-title wow fadeInUp" data-wow-delay=".2s">Glenzy</span>
                                <h2 class="title wow fadeInUp" data-wow-delay=".4s">Enabling Affordable & Instant Solar Loans</h2>
                                <p class="wow fadeInUp" data-wow-delay=".6s">A smarter and cost effective loan to make your Solar loans independent of bargains. Our functionally scalable solutions achieves your financial needs effectively.</p>

                                {/* <Button
              className="btn wow fadeInUp  "
              type="button"
              value={"Start Now 1"}
              handleClick={handleClick}
            /> */}
                                {/* <a href="#" class="btn wow fadeInUp" data-wow-delay=".8s">Start Now</a>  */}
                            </div>
                        </div>
                    </div>
                </div>
         
            </section>
           
            <section class="about-area pb-120" id="howitworks">
                <div class="container">
                    <div class="row align-items-center pt80_solar">
                        <div class="col-xl-7 col-lg-6 order-0 order-lg-2">
                            <div class="about-img-wrap">
                               <img src={about1}  class="wow fadeInRight" data-wow-delay=".2s"/>
                                <img src={about2}  class="wow fadeInRight" data-wow-delay=".2s" />
                                <div class="about-experiences-wrap">
                                    <div class="experiences-item">
                                        <div class="icon">
                                           <img src={abouticon1} />
                                        </div>
                                        <div class="content">
                                            <h6 class="title">Hassle free Loans in 10m</h6>
                                        </div>
                                    </div>
                                    <div class="experiences-item">
                                        <div class="icon">
                                        <img src={abouticon1} />
                                        </div>
                                        <div class="content">
                                            <h6 class="title">Get Customised Applications</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-5 col-lg-6">
                            <div class="about-content">
                                <div class="section-title mb-25 tg-heading-subheading animation-style3">
                                    <span class="sub-title tg-element-title">Glenzy</span>
                                    <h2 class="title tg-element-title">How it Works?</h2>
                                </div>
                                <p>using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy
                                    using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
                                <div class="about-list">
                                    <ul class="list-wrap">
                                        <li><i class="fas fa-check"></i>Using Lorem Ipsum is that it has a more-or-less.</li>
                                        <li><i class="fas fa-check"></i>Using Lorem Ipsum is that it has a more-or-less.</li>
                                        <li><i class="fas fa-check"></i>Using Lorem Ipsum is that it has a more-or-less.</li>
                                    </ul>
                                </div>
                                <a href="#" class="btn">Read More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
           
            <section class="work-area" id="benefits">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-8">
                            <div class="section-title text-center mb-50 tg-heading-subheading animation-style3">
                                <span class="sub-title tg-element-title">Glenzy</span>
                                <h2 class="title tg-element-title">Benefits</h2>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-4 col-md-6">
                            <div class="work-item">
                                <div class="work-thumb">
                                    <img src={cloudimg} />
                                    <h4 class="number">01</h4>
                                </div>
                                <div class="work-content">
                                    <h2 class="title">Solar</h2>
                                    <p>when an unknown printer took a galley of type and scrambled it to type</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-md-6">
                            <div class="work-item">
                                <div class="work-thumb">
                                    <img src={cloudimg} />
                                    <h4 class="number">02</h4>
                                </div>
                                <div class="work-content">
                                    <h2 class="title">Solar</h2>
                                    <p>when an unknown printer took a galley of type and scrambled it to type</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-md-6">
                            <div class="work-item">
                                <div class="work-thumb">
                                    <img src={cloudimg} />
                                    <h4 class="number">03</h4>
                                </div>
                                <div class="work-content">
                                    <h2 class="title">Solar</h2>
                                    <p>when an unknown printer took a galley of type and scrambled it to type</p>
                                </div>
                            </div>
                        </div>
                  
                    </div>
                </div>
            </section>
            
            <div class="area-bg-three" data-background="assets/img/images/area_bg03.jpg">

              
                <section class="services-area-two pb-90" id="contactus">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-lg-12">
                                <div class="section-title white-title text-center mb-60 tg-heading-subheading animation-style3">
                                    <span class="sub-title tg-element-title">Glenzy</span>
                                    <h2 class="title tg-element-title">Contact Us</h2>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="services-item-two">
                                    
                                    <div class="services-content-two">
                                        <div class="icon">
                                            <img src={services_icon01}  />
                                        </div>
                                        <div class="content">
                                            <h2 class="title"><a href="#">Call Us Now</a></h2>
                                            <p>Need help with your Login? No problem! Our team is here to help you. <b>508.475.9013</b></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="services-item-two">
                                   
                                    <div class="services-content-two">
                                        <div class="icon">
                                        <img src={services_icon02}  />
                                        </div>
                                        <div class="content">
                                            <h2 class="title"><a href="#">Request a Quote</a></h2>
                                            <p>If you prefer to communicate via email, please click below.
                                                Please feel free to ask any questions may you have, 
                                                we would love to hear from you.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
               
                <div class="counter-area-three mt_80">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-3 col-sm-6">
                                <div class="counter-item-three">
                                    <div class="counter-content">
                                        <div class="count odometer" data-count="100">
                                          </div>
                                          <div className="countnumber">
                                          100+
                                          </div>
                                        <p>Projects Completed</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-sm-6">
                                <div class="counter-item-three">
                                    <div class="counter-content">
                                        <div class="count odometer" >

                                        </div>
                                        <div className="countnumber">
                                          900k
                                          </div>
                                        <p>Satisfied Customers</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-sm-6">
                                <div class="counter-item-three">
                                    <div class="counter-content">
                                        <div class="count odometer" ></div>
                                        <div className="countnumber">
                                          38
                                          </div>
                                        <p>E-Commerce Sites
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-sm-6">
                                <div class="counter-item-three">
                                    <div class="counter-content">
                                        <div class="count odometer" ></div>
                                        <div className="countnumber">
                                          50
                                          </div>
                                        <p>Awards</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                 

            </div>
            
            <section class="engineer-area  pb-120 mt_80">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-8">
                            <div class="section-title text-center mb-60 tg-heading-subheading animation-style3">
                                <span class="sub-title tg-element-title">Glenzy</span>
                                <h2 class="title tg-element-title">Meet Our Team</h2>
                            </div>
                        </div>
                    </div>
                    <div class=" engineer-active">
                       
                       <Slider {...SliderSec} >
                        
                            <div class="engineer-item">
                                <div class="engineer-thumb">
                                  <img src={team_img1} />
                                    <a class="play-btn popup-video"><i class="fas fa-play"></i></a>
                                </div>
                                <div class="engineer-info-wrap">
                                     
                                    <div class="content">
                                        <h2 class="title"><a href="#">John Smith</a></h2>
                                        <span>Chief Executive Officer</span>
                                    </div>
                                </div>
                            </div>
                       
          
                            <div class="engineer-item">
                                <div class="engineer-thumb">
                                <img src={team_img2} />
                                    <a href="#" class="play-btn popup-video"><i class="fas fa-play"></i></a>
                                </div>
                                <div class="engineer-info-wrap">
                                    
                                    <div class="content">
                                        <h2 class="title"><a href="#">Andreanne</a></h2>
                                        <span>Chief Executive Officer</span>
                                    </div>
                                </div>
                            </div>
                  
                            <div class="engineer-item">
                                <div class="engineer-thumb">
                                <img src={team_img3} />
                                    <a href="#" class="play-btn popup-video"><i class="fas fa-play"></i></a>
                                </div>
                                <div class="engineer-info-wrap">
                                
                                    <div class="content">
                                        <h2 class="title"><a href="#">John Smith</a></h2>
                                        <span>Chief Executive Officer</span>
                                    </div>
                                </div>
                            </div>
                            <div class="engineer-item">
                                <div class="engineer-thumb">
                                <img src={team_img1} />
                                    <a href="#" class="play-btn popup-video"><i class="fas fa-play"></i></a>
                                </div>
                                <div class="engineer-info-wrap">
                                    
                                    <div class="content">
                                        <h2 class="title"><a href="#">Andreanne</a></h2>
                                        <span>Chief Executive Officer</span>
                                    </div>
                                </div>
                            </div>
                       
                        </Slider>
                        </div>
                    {/* </div> */}
                </div>
            </section>
           

            <section class="blog-area pt-115 pb-90">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-8">
                            <div class="section-title text-center mb-60 tg-heading-subheading animation-style3">
                                <span class="sub-title tg-element-title">Our News</span>
                                <h2 class="title tg-element-title">Insights</h2>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-xl-4 col-md-6">
                            <div class="blog-post-item">
                                <div class="blog-post-thumb">
                                   <img src={blogimg}/>
                                </div>
                                <div class="blog-post-content">
                                    <a href="#" class="tag">Solar Loans</a>
                                    <div class="blog-meta">
                                        <ul class="list-wrap">
                                            <li><i class="far fa-user"></i> <a href="#">Admin</a></li>
                                            <li><i class="fas fa-calendar-alt"></i>03 Aug 2023</li>
                                        </ul>
                                    </div>
                                    <h2 class="title"><a href="#">Solar energy is derived from the sun, a giant nuclear reactor that emits energy in the form of electromagnetic radiation.</a></h2>
                                    <a href="#" class="link-btn">Read More<i class="fas fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-md-6">
                            <div class="blog-post-item">
                                <div class="blog-post-thumb">
                                <img src={blogimg3}/>
                                </div>
                                <div class="blog-post-content">
                                    <a href="#" class="tag">Solar Loans</a>
                                    <div class="blog-meta">
                                        <ul class="list-wrap">
                                            <li><i class="far fa-user"></i> <a href="#">Admin</a></li>
                                            <li><i class="fas fa-calendar-alt"></i>03 Aug 2023</li>
                                        </ul>
                                    </div>
                                    <h2 class="title"><a href="#">Solar energy is derived from the sun, a giant nuclear reactor that emits energy in the form of electromagnetic radiation.</a></h2>
                                    <a href="#" class="link-btn">Read More<i class="fas fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-md-6">
                            <div class="blog-post-item">
                                <div class="blog-post-thumb">
                                <img src={blogimg2}/>
                                </div>
                                <div class="blog-post-content">
                                    <a href="#" class="tag">Solar Loans</a>
                                    <div class="blog-meta">
                                        <ul class="list-wrap">
                                            <li><i class="far fa-user"></i> <a href="#">Admin</a></li>
                                            <li><i class="fas fa-calendar-alt"></i>03 Aug 2023</li>
                                        </ul>
                                    </div>
                                    <h2 class="title"><a href="#">Solar energy is derived from the sun, a giant nuclear reactor that emits energy in the form of electromagnetic radiation.</a></h2>
                                    <a href="#" class="link-btn">Read More<i class="fas fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
         
            

        </main>
    
        
        <footer>
            <div class="footer-area footer-bg" >
                <div class="footer-top">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-3 col-lg-4 col-md-7">
                                <div class="footer-widget">
                                    <div class="logo">
                                        <img src={footerlogo} />
                                    </div>
                                    <div class="footer-content">
                                        <p>A smarter and cost effective loan to make your Solar loans independent of bargains. Our functionally scalable solutions achieves your financial needs effectively.</p>
                                       
                                    </div>
                                </div>
                            </div>
                          
                            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div class="footer-widget">
                                    <h2 class="fw-title">Quick Links</h2>
                                    <div class="footer-link">
                                        <ul class="list-wrap">
                                            <li><a href="#"><i class="fas fa-angle-double-right"></i>Home</a></li>
                                            <li><a href="#howitworks"><i class="fas fa-angle-double-right"></i>How it Works</a></li>
                                            <li><a href="#benefits"><i class="fas fa-angle-double-right"></i>Benefits</a></li>
                                            <li><a href="#contactus"><i class="fas fa-angle-double-right"></i>Contact Us</a></li>
                                          
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-5 col-md-6">
                                <div class="footer-widget">
                                    <h2 class="fw-title">Glenzy</h2>
                                    <div class="footer-instagram">
                                        <ul class="list-wrap">
                                            <li>
                                                <img src={footerimg1}/>
                                            </li>
                                            <li>
                                                 <img src={footerimg2}/>
                                            </li>
                                            <li>
                                                <img src={footerimg1}/>
                                            </li>
                                            <li>
                                                 <img src={footerimg2}/>
                                            </li>
                                            <li>
                                            <img src={footerimg1}/>
                                            </li>
                                            <li>
                                                 <img src={footerimg2}/>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                   
                    </div>
                </div>
                <div class="footer-bottom">
                    <div class="container">
                        <div class="row aligncenter">
                            <div class="col-md-6">
                                <div class="copyright-text">
                                    <p> Copyright © 2020-2023 Glenzy Private Limited. All
                                    Rights Reserved.</p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="footer-bootom-menu">
                                    <ul class="list-wrap">
                                        <li><a href="#">Privacy Policy</a></li>
                                        <li><a href="#">Terms & Conditions</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
      

    </div>

    </div>
    
    </div>




  );
}

export default Landing;
